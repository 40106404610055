import {
    ErrorResponse,
    InternalErrorResponse,
    TSourceKey,
    TUTMMediumKey,
} from 'shared/types';

/* Errors */
type ErrorTypes =
    | 'customer_not_found'
    | 'payload_parse_exception'
    | 'customer_to_young'
    | 'phone_not_available';
type DetailsType = 'field_error' | 'rule_error';

type DetailsCode =
    | 'required'
    | 'invalid'
    | 'blank'
    | 'max_length'
    | 'min_length'
    | 'datetime;';

type DetailsMessage = Record<string, string[]> | string;

export type CustomerErrorResponse = ErrorResponse<
    ErrorTypes,
    DetailsType,
    DetailsCode,
    DetailsMessage
>;

/* Common */

export type TCustomerData = {
    phone_number: string;
    birthday: string;
    full_name: string;
    customer_id: string;
};

/* Update customer */

export type UpdateCustomerErrorResponse =
    | CustomerErrorResponse
    | InternalErrorResponse;

export type UpdateCustomerPayload = Partial<Omit<TCustomerData, 'customer_id'>>;

// response is 200 ok with empty response body
export type UpdateCustomer = (
    customerId: string,
    payload: UpdateCustomerPayload
) => Promise<Response>;

export type TUpdateCustomerUtilReturn = Promise<{
    error: Record<
        keyof Omit<TCustomerData, 'customer_id'> | 'generic',
        string
    > | null;
}>;

/* Join customer */

export type JoinCustomerPayload = {
    merchant_id: string;
    source: TSourceKey;
    medium?: TUTMMediumKey;
    campaign_id?: string;
};

export type TJoinCustomerUtilReturn = Promise<{
    error: string | null;
}>;

export type JoinCustomerErrorResponse = ErrorResponse | InternalErrorResponse;

// response is 200 ok with empty response body
export type JoinCustomer = (
    customerId: string,
    payload: JoinCustomerPayload
) => Promise<Response>;

/* Create customer */

export type CreateCustomerErrorResponse =
    | ErrorResponse<
          ErrorTypes,
          DetailsType,
          DetailsCode,
          {
              customer_info: Record<
                  keyof Partial<Omit<TCustomerData, 'customer_id'>>,
                  string[]
              >;
          }
      >
    | InternalErrorResponse;

export type CreateCustomerResponse = Pick<TCustomerData, 'customer_id'>;

export type CreateCustomerPayload = {
    merchant_id: string;
    email?: string;
    source: TSourceKey;
    medium?: TUTMMediumKey;
    customer_info?: Partial<Omit<TCustomerData, 'customer_id'>>;
    campaign_id?: string;
};

export type VerifyPhonePayload = {
    phone_number: string;
    code: string;
    customer_id: string;
};

// response is 200 ok with empty response body
export type CreateCustomer = (
    payload: CreateCustomerPayload
) => Promise<Response>;

export type TCreateCustomerUtilReturn = Promise<{
    error: Record<
        keyof Omit<TCustomerData, 'customer_id'> | 'email' | 'generic',
        string
    > | null;
    data: CreateCustomerResponse | null;
}>;

/* Get customer status */

export enum CustomerStatus {
    // Customer doesn't exist
    NOT_FOUND = 'not_found',
    // Customer exists, but is not connected to merchant
    NOT_CONNECTED = 'not_connected',
    // Customer exists and is connected to merchant
    CONNECTED = 'connected',
}

export type TCustomerStatus = `${CustomerStatus}`;

export type GetCustomerStatusResponse = {
    status: TCustomerStatus;
    customer_id: string;
};

export type GetCustomerStatusErrorResponse = ErrorResponse;

export type GetCustomerStatus = (args: {
    merchantId: string;
    email?: string;
    phone_number?: string;
}) => Promise<Response>;

export type TGetCustomerStatusUtilReturn = Promise<{
    error: Record<'email' | 'phone_number' | 'generic', string> | null;
    data: GetCustomerStatusResponse | null;
}>;
